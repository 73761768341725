import { useRef, useEffect } from "react";
import gsap from "gsap";

function Logo() {
  const bounceref = useRef(null); //Mobile Menu ref
  const swingref = useRef(null); //G swing Menu ref

  useEffect(() => {
    gsap.from(bounceref.current, {
      y: -5,
      repeat: -1,
      duration: 1.5,
      ease: "bounce",
      yoyo: true,
    });
    gsap.to(bounceref.current, {
      y: 0,
      repeat: -1,
      duration: 1.5,
      ease: "bounce",
      yoyo: true,
    });
    gsap.from(swingref.current, {
      rotationY: 90,
      repeat: -1,
      duration: 1.5,
      transformOrigin: "center",
      yoyo: true,
    });
    gsap.to(swingref.current, {
      rotationX: 0,
      repeat: -1,
      duration: 1.5,
      transformOrigin: "center",
      yoyo: true,
    });
  }, []);

  return (
    <h1 style={{ color: "var(--black)" }}>
      RAI S
      <span
        ref={bounceref}
        style={{ display: "inline-block", color: "var(--purple-dark)" }}
      >
        o
      </span>
      nahan
      <span
        ref={swingref}
        style={{ display: "inline-block", color: "var(--purple-dark)" }}
      >
        g
      </span>
    </h1>
  );
}

export default Logo;
