import { useRef, useEffect } from "react";
import gsap from "gsap";
import styled from "styled-components";
import { useAtom } from "jotai";
import { darkModeAtom } from "utils/ThemeAtom";
import { device } from "styles/Breakpoint";

function Switch() {
  let hamRef = useRef<HTMLDivElement>(null);

  const [darkMode, setDarkMode] = useAtom(darkModeAtom);
  const toggleDarkMode = () => setDarkMode(!darkMode);

  useEffect(() => {
    if (hamRef) {
      gsap.to(hamRef.current, 1, {
        height: "0",
      });
      gsap.to(hamRef.current, 1, {
        height: "20px",
      });
    }
    return () => {};
  }, [hamRef]);

  const fillSq = () => {
    if (hamRef.current)
      gsap.to(hamRef.current.children, 0.2, {
        height: "100%",
        top: 0,
      });
  };

  const unfillSq = () => {
    if (hamRef.current)
      gsap.to(hamRef.current.children, 0.2, {
        height: "0",
        bottom: 0,
        top: "unset",
      });
  };

  return (
    <TheHam
      ref={hamRef}
      onMouseEnter={fillSq}
      onMouseLeave={unfillSq}
      onClick={toggleDarkMode}
    >
      <span></span>
    </TheHam>
  );
}

export default Switch;

export const TheHam = styled.div`
  width: 20px;
  height: 0;
  border: 2px solid var(--black);
  cursor: pointer;
  display: inline-block;
  position: relative;
  overflow: hidden;
  &.clicked {
    height: 2px;
    background-color: var(--black);
  }
  span {
    position: absolute;
    content: "";
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    transition: 0.7s ease;
  }
  @media ${device.mobileL} {
    transform: scale(0.8);
  }
`;
