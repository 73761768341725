import { useRef, useState, useEffect } from "react";
import useOutsideclick from "hooks/useOutsideClick";
import {
  IndiaSvg,
  JapanSvg,
  KoreaSvg,
  NepalSvg,
  RussiaSvg,
  USSvg,
} from "icons/flag";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";
import gsap from "gsap";

function Lang() {
  const { lang } = useTranslation("common");
  const { pathname } = useRouter();
  const [open, setOpen] = useState(false);
  const bxRef = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);
  const outclick = useOutsideclick(bxRef);

  //Detecting outer click
  useEffect(() => {
    outclick && setOpen(false);
  }, [outclick]);

  useEffect(() => {
    if (dropRef.current)
      if (open) {
        gsap.fromTo(
          dropRef.current,
          {
            height: 0,
          },
          {
            height: "auto",
          }
        );
        gsap.fromTo(
          dropRef.current.children,
          {
            x: -100,
          },
          {
            x: 0,
            stagger: {
              amount: 0.1,
            },
          }
        );
      } else {
        gsap.fromTo(
          dropRef.current,
          {
            height: "auto",
          },
          {
            height: 0,
          }
        );
        gsap.fromTo(
          dropRef.current.children,
          {
            x: 0,
          },
          {
            x: -100,
          }
        );
      }
  }, [open]);

  // Click check
  const click = () => {
    open ? setOpen(false) : setOpen(true);
  };

  return (
    <Drop>
      <ActiveLang ref={bxRef} onClick={click}>
        {lang}
      </ActiveLang>
      <Dropped ref={dropRef}>
        <Country color="#1b60e6" href={pathname} locale="en">
          <USSvg />
          <span>en-US</span>
        </Country>
        <Country color="#d7341f" href={pathname} locale="np">
          <NepalSvg />
          <span>np-NP</span>
        </Country>
        <Country color="#d7341f" href={pathname} locale="ko">
          <KoreaSvg />
          <span>ko-KO</span>
        </Country>
        <Country color="#255ef0" href={pathname} locale="jp">
          <JapanSvg />
          <span>jp-JP</span>
        </Country>
        <Country color="#f2b200" href={pathname} locale="hi">
          <IndiaSvg />
          <span>hi-IN</span>
        </Country>
        <Country color="#428bc1" href={pathname} locale="ru">
          <RussiaSvg />
          <span>ru-RU</span>
        </Country>
      </Dropped>
    </Drop>
  );
}

export default Lang;

const Drop = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 1;
`;

const ActiveLang = styled.div`
  font-size: 14px;
  color: var(--black);
`;

const Dropped = styled.div`
  position: absolute;
  width: 140px;
  max-height: 400px;
  right: 0;
  top: 100%;
  padding: 10px;
  border-radius: 14px;
  overflow: hidden;
  z-index: 1;
  background: var(--white);
`;

const Country = styled(Link)<{ color: string }>`
  display: flex;
  align-items: center;
  color: var(--black);
  padding: 10px 0;
  border-bottom: 1px solid ${({ color }) => color};
  svg {
    width: 30px;
    height: 30px;
  }
  span {
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
  }
`;
