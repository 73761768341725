export const menus = [
  {
    name: "works",
    link: "/works",
    blank: false,
  },
  {
    name: "blogs",
    link: "https://sonahangrai.com",
    blank: true,
  },
  {
    name: "resume",
    link: "/resume",
    blank: false,
  },
  {
    name: "cv",
    link: "https://docs.google.com/document/d/1dAqtwmXsu6DJOwHTBCUrsNHHSbQ16km4/edit?usp=sharing&ouid=116828683280383528785&rtpof=true&sd=true",
    blank: true,
  },
  {
    name: "github",
    link: "https://github.com/sonangrai",
    blank: true,
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/in/sonahang-rai/",
    blank: true,
  },
];
