//USA Flag
export const USSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90px"
      height="90px"
      viewBox="0 0 36 36"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      fill="#000000"
      stroke="#000000"
      strokeWidth="0"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g id="SVGRepo_iconCarrier">
        <path
          fill="#B22334"
          d="M35.445 7C34.752 5.809 33.477 5 32 5H18v2h17.445zM0 25h36v2H0zm18-8h18v2H18zm0-4h18v2H18zM0 21h36v2H0zm4 10h28c1.477 0 2.752-.809 3.445-2H.555c.693 1.191 1.968 2 3.445 2zM18 9h18v2H18z"
        />

        <path
          fill="#EEE"
          d="M.068 27.679c.017.093.036.186.059.277c.026.101.058.198.092.296c.089.259.197.509.333.743L.555 29h34.89l.002-.004a4.22 4.22 0 0 0 .332-.741a3.75 3.75 0 0 0 .152-.576c.041-.22.069-.446.069-.679H0c0 .233.028.458.068.679zM0 23h36v2H0zm0-4v2h36v-2H18zm18-4h18v2H18zm0-4h18v2H18zM0 9zm.555-2l-.003.005L.555 7zM.128 8.044c.025-.102.06-.199.092-.297a3.78 3.78 0 0 0-.092.297zM18 9h18c0-.233-.028-.459-.069-.68a3.606 3.606 0 0 0-.153-.576A4.21 4.21 0 0 0 35.445 7H18v2z"
        />

        <path fill="#3C3B6E" d="M18 5H4a4 4 0 0 0-4 4v10h18V5z" />

        <path
          fill="#FFF"
          d="M2.001 7.726l.618.449l-.236.725L3 8.452l.618.448l-.236-.725L4 7.726h-.764L3 7l-.235.726zm2 2l.618.449l-.236.725l.617-.448l.618.448l-.236-.725L6 9.726h-.764L5 9l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L9 9l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L13 9l-.235.726zm-8 4l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L5 13l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L9 13l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L13 13l-.235.726zm-6-6l.618.449l-.236.725L7 8.452l.618.448l-.236-.725L8 7.726h-.764L7 7l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L11 7l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L15 7l-.235.726zm-12 4l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L3 11l-.235.726zM6.383 12.9L7 12.452l.618.448l-.236-.725l.618-.449h-.764L7 11l-.235.726h-.764l.618.449zm3.618-1.174l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L11 11l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L15 11l-.235.726zm-12 4l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L3 15l-.235.726zM6.383 16.9L7 16.452l.618.448l-.236-.725l.618-.449h-.764L7 15l-.235.726h-.764l.618.449zm3.618-1.174l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L11 15l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L15 15l-.235.726z"
        />
      </g>
    </svg>
  );
};

//Nepal
export const NepalSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90px"
      height="90px"
      viewBox="0 0 64 64"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      fill="#000000"
      stroke="#000000"
      strokeWidth="0"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g id="SVGRepo_iconCarrier">
        <path
          d="M44.5 56.5L20 32h24L18.7 6.7h-2.8A29.87 29.87 0 0 0 2 32c0 16.6 13.4 30 30 30c4.4 0 8.7-1 12.5-2.7v-2.8z"
          fill="#ed4c5c"
        />

        <path
          d="M32 2c-4.4 0-8.7 1-12.5 2.7v4.8l21 21h-21v3.2l23.6 23.6h5C56.5 52 62 42.6 62 32C62 15.4 48.6 2 32 2z"
          fill="#f9f9f9"
        />

        <path
          d="M19.5 4.7c-1.3.6-2.5 1.2-3.7 2L39.2 30h-24l29.3 29.3c1.3-.6 2.5-1.2 3.7-2L24.8 34h24L19.5 4.7z"
          fill="#428bc1"
        />

        <g fill="#ffffff">
          <path d="M19.6 21.8c.3-.5.4-1.2.4-1.8c0-2.2-1.8-4-4-4s-4 1.8-4 4c0 .7.2 1.3.4 1.8C10.5 21 9 20 9 20s1.5 6 7 6c5.6 0 7-6 7-6s-1.5 1-3.4 1.8" />

          <path d="M16 39.6l1.6-1.6l.6 2.2l2.2-.6l-.6 2.2l2.2.6l-1.6 1.6l1.6 1.6l-2.2.6l.6 2.2l-2.2-.6l-.6 2.2l-1.6-1.6l-1.6 1.6l-.6-2.2l-2.2.6l.6-2.2l-2.2-.6l1.6-1.6l-1.6-1.6l2.2-.6l-.6-2.2l2.2.6l.6-2.2z" />
        </g>
      </g>
    </svg>
  );
};

//Korea
export const KoreaSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90px"
      height="90px"
      viewBox="0 0 36 36"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      fill="#000000"
      stroke="#000000"
      strokeWidth="0"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g id="SVGRepo_iconCarrier">
        <path
          fill="#EEE"
          d="M36 27a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v18z"
        />

        <path
          fill="#C60C30"
          d="M21.441 13.085a6 6 0 0 0-8.356 1.474A3.001 3.001 0 0 0 18 18a3 3 0 0 1 4.915 3.442a6 6 0 0 0-1.474-8.357z"
        />

        <path
          fill="#003478"
          d="M22.178 17.264A3 3 0 0 0 18 18a3.001 3.001 0 0 1-4.915-3.442a6 6 0 1 0 9.829 6.882a2.997 2.997 0 0 0-.736-4.176z"
        />

        <path
          d="M24.334 25.572l1.928-2.298l.766.643l-1.928 2.298zm2.57-3.063l1.928-2.297l.766.643l-1.928 2.297zm-1.038 4.351l1.928-2.297l.766.643l-1.928 2.297zm2.572-3.066l1.93-2.297l.766.644l-1.93 2.296zm-1.041 4.352l1.93-2.297l.765.643l-1.929 2.297zm2.571-3.065l1.927-2.3l.767.643l-1.927 2.3zm.004-14.162l.766-.643l1.93 2.299l-.767.643zM27.4 7.853l.766-.643l1.928 2.299l-.767.642zm-1.533 1.288l.766-.643l4.5 5.362l-.766.643zm-1.532 1.284l.767-.643l1.927 2.298l-.766.642zm2.57 3.065l.766-.643l1.93 2.297l-.765.643zM6.4 20.854l.766-.643l4.499 5.363l-.767.643zM4.87 22.14l.765-.642l1.929 2.298l-.767.643zm2.567 3.066l.766-.643l1.93 2.297l-.766.643zm-4.101-1.781l.766-.643l4.5 5.362l-.767.643zm-.001-10.852l4.498-5.362l.767.642l-4.5 5.363zm1.532 1.287l4.5-5.363l.766.643l-4.5 5.362zM6.4 15.145l4.5-5.363l.766.643l-4.5 5.363z"
          fill="#292F33"
        />
      </g>
    </svg>
  );
};

//Japan
export const JapanSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="90px"
      width="90px"
      version="1.1"
      id="Capa_1"
      viewBox="0 0 473.68 473.68"
      fill="#000000"
      stroke="#000000"
      strokeWidth="0"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g id="SVGRepo_iconCarrier">
        <g>
          <circle fill="#FFFFFF" cx="236.85" cy="236.85" r="236.83" />
          <circle fill="#FFFFFF" cx="236.85" cy="236.85" r="236.83" />
        </g>
        <path
          fill="#EFECEC"
          d="M460.143,157.874c-0.026-0.075-0.049-0.138-0.075-0.206C429.756,72.2,351.789,9.319,258.109,0.972 c36.248,19.872,46.834,82.832,56.109,156.901c6.335,50.593,6.376,106.339,0.12,156.995c-9.196,74.47-19.827,137.874-56.228,157.829 c93.848-8.362,171.927-71.46,202.12-157.156c0.079-0.228,0.146-0.453,0.228-0.673c8.526-24.438,13.219-50.682,13.219-78.026 C473.677,209.139,468.879,182.573,460.143,157.874z"
        />{" "}
        <path
          fill="#F3F2F2"
          d="M326.412,157.874c-9.27-74.07-32.052-137.029-68.303-156.901C251.097,0.352,244.007,0,236.835,0 C133.806,0,46.187,65.802,13.608,157.668c-0.026,0.067-0.049,0.131-0.075,0.206C4.798,182.573,0,209.139,0,236.842 c0,27.348,4.693,53.588,13.219,78.026c0.079,0.221,0.15,0.445,0.228,0.673c32.452,92.102,120.19,158.135,223.387,158.135 c7.173,0,14.263-0.352,21.274-0.98c36.401-19.954,59.227-83.359,68.423-157.829C332.788,264.208,332.747,208.466,326.412,157.874z"
        />{" "}
        <circle fill="#EC1C24" cx="236.85" cy="236.85" r="87.25" />
      </g>
    </svg>
  );
};

//India Bhosadike
export const IndiaSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90px"
      height="90px"
      viewBox="0 0 64 64"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      fill="#000000"
      stroke="#000000"
      strokeWidth="0"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g id="SVGRepo_iconCarrier">
        <path
          d="M31.8 2c-13 0-24.1 8.4-28.2 20h56.6C56 10.4 44.9 2 31.8 2z"
          fill="#f2b200"
        />

        <path
          d="M31.8 62c13.1 0 24.2-8.3 28.3-20H3.6c4.1 11.7 15.2 20 28.2 20z"
          fill="#83bf4f"
        />

        <path
          d="M3.6 22c-1.1 3.1-1.7 6.5-1.7 10s.6 6.9 1.7 10h56.6c1.1-3.1 1.7-6.5 1.7-10s-.6-6.9-1.7-10H3.6"
          fill="#ffffff"
        />

        <circle cx="31.8" cy="32" r="8" fill="#428bc1" />

        <circle cx="31.8" cy="32" r="7" fill="#ffffff" />

        <g fill="#428bc1">
          <circle cx="29.2" cy="25.5" r=".5" />

          <circle cx="27.6" cy="26.4" r=".5" />

          <circle cx="26.3" cy="27.7" r=".5" />

          <circle cx="25.4" cy="29.3" r=".5" />

          <circle cx="24.9" cy="31.1" r=".5" />

          <circle cx="24.9" cy="32.9" r=".5" />

          <circle cx="25.4" cy="34.7" r=".5" />

          <circle cx="26.3" cy="36.3" r=".5" />

          <circle cx="27.6" cy="37.6" r=".5" />

          <circle cx="29.2" cy="38.5" r=".5" />

          <circle cx="30.9" cy="38.9" r=".5" />

          <path d="M32.3 39c0-.3.2-.5.4-.6c.3 0 .5.2.6.4c0 .3-.2.5-.4.6c-.4.1-.6-.1-.6-.4" />

          <circle cx="34.5" cy="38.5" r=".5" />

          <circle cx="36.1" cy="37.6" r=".5" />

          <circle cx="37.4" cy="36.3" r=".5" />

          <circle cx="38.3" cy="34.7" r=".5" />

          <circle cx="38.8" cy="32.9" r=".5" />

          <path d="M38.8 31.6c-.3 0-.5-.2-.6-.4c0-.3.2-.5.4-.6c.3 0 .5.2.6.4c.1.3-.1.5-.4.6" />

          <circle cx="38.3" cy="29.3" r=".5" />

          <circle cx="37.4" cy="27.7" r=".5" />

          <circle cx="36.1" cy="26.4" r=".5" />

          <path d="M35 25.7c-.1.3-.4.4-.7.3c-.3-.1-.4-.4-.3-.7c.1-.3.4-.4.7-.3c.3.2.4.5.3.7" />

          <path d="M33.2 25.1c0 .3-.3.5-.6.4c-.3 0-.5-.3-.4-.6c0-.3.3-.5.6-.4c.3.1.5.4.4.6" />

          <path d="M31.4 25c0 .3-.2.5-.4.6c-.3 0-.5-.2-.6-.4c0-.3.2-.5.4-.6c.3-.1.6.1.6.4" />

          <circle cx="31.8" cy="32" r="1.5" />

          <path d="M31.8 25l-.2 4.3l.2 2.7l.3-2.7z" />

          <path d="M30 25.2l.9 4.3l.9 2.5l-.4-2.7z" />

          <path d="M28.3 25.9l2 3.9l1.5 2.2l-1.1-2.5z" />

          <path d="M26.9 27l2.9 3.3l2 1.7l-1.7-2.1z" />

          <path d="M25.8 28.5l3.6 2.4l2.4 1.1l-2.2-1.6z" />

          <path d="M25.1 30.2l4.1 1.3l2.6.5l-2.5-.9z" />

          <path d="M24.8 32l4.4.2l2.6-.2l-2.6-.2z" />

          <path d="M25.1 33.8l4.2-.9l2.5-.9l-2.6.5z" />

          <path d="M25.8 35.5l3.8-1.9l2.2-1.6l-2.4 1.1z" />

          <path d="M26.9 36.9l3.2-2.8l1.7-2.1l-2 1.7z" />

          <path d="M28.3 38.1l2.4-3.7l1.1-2.4l-1.5 2.2z" />

          <path d="M30 38.8l1.4-4.1l.4-2.7l-.9 2.5z" />

          <path d="M31.8 39l.3-4.3l-.3-2.7l-.2 2.7z" />

          <path d="M33.6 38.8l-.8-4.3l-1-2.5l.5 2.7z" />

          <path d="M35.3 38.1l-1.9-3.9l-1.6-2.2l1.2 2.5z" />

          <path d="M36.8 36.9l-2.9-3.2l-2.1-1.7l1.8 2.1z" />

          <path d="M37.9 35.5l-3.6-2.4l-2.5-1.1l2.2 1.6z" />

          <path d="M38.6 33.8l-4.1-1.3l-2.7-.5l2.6.9z" />

          <path d="M38.8 32l-4.3-.3l-2.7.3l2.7.2z" />

          <path d="M38.6 30.2l-4.2.9l-2.6.9l2.7-.5z" />

          <path d="M37.9 28.5L34 30.4L31.8 32l2.5-1.1z" />

          <path d="M36.8 27.1l-3.2 2.8l-1.8 2.1l2.1-1.7z" />

          <path d="M35.3 25.9L33 29.6L31.8 32l1.6-2.2z" />

          <path d="M33.7 25.2l-1.4 4.1l-.5 2.7l1-2.5z" />
        </g>
      </g>
    </svg>
  );
};

//Russia
export const RussiaSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90px"
      height="90px"
      viewBox="0 0 64 64"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      fill="#000000"
      stroke="#000000"
      strokeWidth="0"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g id="SVGRepo_iconCarrier">
        <path
          d="M31.9 2c-13 0-24.1 8.4-28.2 20h56.6C56.1 10.4 45 2 31.9 2z"
          fill="#f9f9f9"
        />

        <path
          d="M31.9 62c13.1 0 24.2-8.4 28.3-20H3.7c4.1 11.7 15.2 20 28.2 20z"
          fill="#ed4c5c"
        />

        <path
          d="M3.7 22C2.6 25.1 2 28.5 2 32s.6 6.9 1.7 10h56.6c1.1-3.1 1.7-6.5 1.7-10s-.6-6.9-1.7-10H3.7"
          fill="#428bc1"
        />
      </g>
    </svg>
  );
};
