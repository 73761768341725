import { RefObject, useEffect, useState } from "react";

/**
 * Hook that returns true if click outside of the passed ref
 */
function useOutsideclick(ref: any) {
  const [out, setOut] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOut(true);
      } else {
        setOut(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return out;
}

export default useOutsideclick;
