import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Link from "next/link";
import { device } from "styles/Breakpoint";
import { menus } from "./menu";
import useTranslation from "next-translate/useTranslation";
import gsap from "gsap";
import useOutsideclick from "hooks/useOutsideClick";

function Mobilenav() {
  const [open, setopen] = useState(false);
  const mens = useRef<HTMLUListElement>(null);
  const mobM = useRef<HTMLDivElement>(null);
  const outclick = useOutsideclick(mobM);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (outclick) setopen(false);
  }, [outclick]);

  useEffect(() => {
    if (mens.current && open)
      gsap.fromTo(
        mens.current.children,
        {
          x: -100,
        },
        {
          x: 0,
          stagger: {
            amount: 0.1,
          },
        }
      );
  }, [open]);

  return (
    <div ref={mobM}>
      <MbNav open={open} onClick={() => setopen((open) => !open)}>
        <span></span>
        <span></span>
      </MbNav>
      <MobileMenu open={open}>
        <ul ref={mens}>
          {menus.map((lk) => (
            <li key={lk.name}>
              <Link target={lk.blank ? "_blank" : "_self"} href={lk.link}>
                {t(`header.${lk.name}`)}
              </Link>
            </li>
          ))}
        </ul>
      </MobileMenu>
    </div>
  );
}

export default Mobilenav;

const MbNav = styled.div<{ open: boolean }>`
  display: none;
  cursor: pointer;
  span {
    display: block;
    height: 2px;
    width: 30px;
    margin: 10px 0;
    background-color: var(--black);
    transition: 0.4s;
    &:first-child {
      transform: ${({ open }) => (open ? "translateY(5px)" : "unset")};
    }
    &:last-child {
      transform: ${({ open }) => (open ? "translateY(-5px)" : "unset")};
    }
  }
  @media ${device.laptop} {
    display: block;
  }
`;

const MobileMenu = styled.div<{ open: boolean }>`
  position: fixed;
  z-index: 2;
  top: 0;
  left: ${({ open }) => (open ? "0" : "-350px")};
  height: 100vh;
  width: 300px;
  background-color: var(--white);
  padding: 24px;
  transition: 0.3s;
  @media ${device.mobileL} {
    width: 80%;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 60%;
    li {
      a {
        color: var(--grayWhite);
        font-size: 30px;
        @media ${device.mobileL} {
          font-size: 20px;
        }
      }
    }
  }
`;
