import styled from "styled-components";
import { device } from "./Breakpoint";

//Container
export const Container = styled.div`
  max-width: 1392px;
  width: 92%;
  margin: 0 auto;
  @media (max-width: ${device.mobileM}) {
    width: 98%;
  }
`;

//top title
export const TopTitle = styled.div<{ align?: string }>`
  text-transform: uppercase;
  text-align: ${({ align }) => (align ? align : "center")};
  font-size: 15px;
  letter-spacing: 3px;
  color: var(--grayWhite);
  @media ${device.laptopL} {
    font-size: 12px;
  }
  @media ${device.laptop} {
    font-size: 10px;
  }
`;

//Section title
export const SectionTitle = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  color: var(--black);
  @media ${device.laptop} {
    font-size: 24px;
  }
  @media ${device.mobileL} {
    font-size: 20px;
  }
`;

export const Title = styled.h2<{ align?: string }>`
  font-size: 40px;
  font-weight: 600;
  text-align: ${({ align }) => (align ? "center" : align)};
  margin: 10px 0;
  color: var(--black);
  @media ${device.mobileL} {
    font-size: 20px;
  }
`;

export const Para = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--grayWhite);
  letter-spacing: 0.2px;
`;

export const BlueButton = styled.button`
  background: linear-gradient(
      104.42deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 60.23%
    ),
    linear-gradient(104.42deg, #4745d0 0%, #2a27c9 60.23%);
  box-shadow: inset -4px -4px 4px rgba(0, 0, 0, 0.1),
    inset 4px 4px 4px rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  border: 0;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  cursor: pointer;
`;
