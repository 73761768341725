import Head from "next/head";
import { ReactNode } from "react";
import Header from "./Header/";

interface props {
  title: string;
  description: string;
  image: string;
  children?: ReactNode;
}

/**
 *
 * @returns meta tags for og
 */
function index({ title, description, image, children }: props) {
  return (
    <>
      <Head>
        <title>{title}</title>

        {/* Essential META Tags */}
        <meta property="og:title" content={title} />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={
            image ||
            "https://res.cloudinary.com/drqgginx9/image/upload/v1640052210/mynextsite/favicon_mquhxf.png"
          }
        />
        <meta property="og:url" content="https://sonahangrai.com.np" />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Non-Essential, But Recommended */}
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Sonahang Rai" />
        <meta name="twitter:image:alt" content="Sonahang Rai" />
      </Head>
      <Header />
      {children}
    </>
  );
}

export default index;
