import Link from "next/link";
import styled from "styled-components";
import useTranslation from "next-translate/useTranslation";
import { menus } from "./menu";

function Nav() {
  const { t } = useTranslation("common");

  return (
    <SiteNav>
      <UL>
        {menus.map((lk) => (
          <li key={lk.name}>
            <Link target={lk.blank ? "_blank" : "_self"} href={lk.link}>
              {t(`header.${lk.name}`)}
            </Link>
          </li>
        ))}
      </UL>
    </SiteNav>
  );
}

export default Nav;

const SiteNav = styled.nav``;

const UL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  li {
    a {
      display: inline-block;
      margin: 0 1em;
      font-size: 14px;
      font-weight: 600;
      color: var(--grayWhite);
    }
  }
`;
