import { useRef, useEffect } from "react";
import gsap from "gsap";
import Link from "next/link";
import styled from "styled-components";
import Logo from "./Logo";
import Nav from "./Nav";
import Switch from "./Switch";
import Lang from "./Lang";
import { device } from "styles/Breakpoint";
import Mobilenav from "./Mobilenav";

function Header() {
  const heado = useRef<HTMLHeadElement>(null);

  useEffect(() => {
    if (heado.current)
      gsap.fromTo(
        heado.current,
        {
          y: -200,
          ease: "power4.out",
        },
        {
          y: 0,
        }
      );
  }, []);

  return (
    <MyHeader ref={heado}>
      <SiteLogo>
        <Link href="/">
          <Logo />
        </Link>
      </SiteLogo>

      <SiteNav>
        <Nav />
      </SiteNav>

      <ThemeSwitch>
        <Lang />
        <Switch />
        <Mobilenav />
      </ThemeSwitch>
    </MyHeader>
  );
}

export default Header;

// Header Container
const MyHeader = styled.header`
  position: sticky;
  top: 0;
  padding: 20px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  z-index: 2;
  @media ${device.mobileL} {
    padding: 20px;
  }
`;

const SiteLogo = styled.div`
  font-weight: 600;
  h1 {
    font-size: 20px;
    @media ${device.mobileL} {
      font-size: 18px;
    }
  }
`;

const SiteNav = styled.div`
  @media ${device.laptop} {
    display: none;
  }
`;

const ThemeSwitch = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
`;
